<template>
  <div>
    <el-divider content-position="left">发货单详情</el-divider>
    <span style="color:red">请填写备注后再完成发货</span><br><br>
    <el-table :data="labelDetails" style="width: 100%" border id="labelTable">
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column prop="manufacturer.name" label="制造商" />
      <el-table-column prop="manufacturer.code" label="制造商代码"  />
      <el-table-column prop="labelCount" label="防伪码标签（套）"  />
      <el-table-column prop="barcodeNumber" label="条形码标签总数（枚）" >
        <template #default="scope">
            <span>{{scope.row.barcodeNumber?scope.row.barcodeNumber:0}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="order.orderBatch" label="订单批次" />
      <el-table-column prop="productionRemark" label="发货备注" width="450">
        <template #default="scope">
            <el-input size="small" maxlength="50" show-word-limit v-model="scope.row.productionRemark" placeholder="请输入内容" @change="handleEdit(scope.$index, scope.row)"></el-input>
        </template>
      </el-table-column>
    </el-table><br>
    <el-button size="mini" type="primary" @click="handleSave" v-waves>暂存</el-button>
    <el-button size="mini" type="primary" @click="handleReceipt" v-waves>确定并发货</el-button>
  </div>
</template>

<script>
import orderProductListApi from '@/apis/order/orderProductList'
import orderProductInfoApi from '@/apis/order/orderProductInfo'
export default {
  props: {
    workflowNode: {
      type: Object
    }
  },
  data() {
    return {
      orderProductListApi,
      orderProductInfoApi,
      orderProductApi: this.workflowNode.isInfo ? orderProductInfoApi:orderProductListApi,
      orderInfo: undefined,
      labelDetails: [],
      params:{
        businessKey: undefined,
        localVariables: {
            approved: undefined,
            note: undefined
        }
      },
    }
  },
  watch:{
    workflowNode:{
        handler: function(newV, oldV){
          this.getOrderInfoById()
          this.getLabelInfoByOrderId()
        }
      }
    },
  created() {
    this.getOrderInfoById()
    this.getLabelInfoByOrderId()
  },
  mounted(){
  },
  methods:{
    getOrderInfoById(){
      if(this.workflowNode.businessKey){
        this.orderProductApi.getOrderInfoById(this.workflowNode.businessKey).then(resp=>{
          if(resp.data){
            this.orderInfo = resp.data
          }
        })
      }
    },
    getLabelInfoByOrderId(){
      if(this.workflowNode.businessKey){
        this.orderProductApi.getLabelInfoByOrderId(this.workflowNode.businessKey).then(resp =>{
          if(resp.data){
            var labels = resp.data.map(labelInfo=>{
              if(labelInfo.model.page > 1){
                labelInfo.barcodeNumber = labelInfo.labelCount * labelInfo.model.page
              }else{
                labelInfo.barcodeNumber = 0
              }
              return labelInfo
            })
            this.labelDetails = labels.filter(item => item.labelState.name==='IN_EFFECT')
          }
        })
      }
    },
    handleReceipt(){
      this.$confirm('确认后无法再次修改备注,确定要提交吗？', '警告', {
        type: 'warning'
      }).then(() => {
        this.orderProductApi.getTasks({
          assignee: this.$store.state.user.account,
          processDefinitionKeys: [this.orderInfo.processDefinitionKey],
          businessKey: this.orderInfo.id
        }).then(resp=>{
          var task = resp.data[0]
          if (task) {
            this.task = task
            this.taskId = task.id
            this.params.localVariables.approved = true
            this.params.localVariables.note = '通过'
            var orderStatus = {
              orderId: this.orderInfo.id,
              status:  {
                type: '完成并发货',
                name: 'SHIPPED'
              }
            }
            this.orderProductApi.audit({labelDTOS: this.labelDetails,orderStatus: orderStatus,taskId:this.taskId,...this.params}).then(resp=>{
              if (resp.data) {
                this.$message.success("成功")
                this.$emit('close',false)
              }
            })
          }else{
            this.$message.warning("无权操作")
            this.task = null
            this.taskId = null
          }
        })
      })
    },
    handleEdit(index, row) {
      console.log(index, row)
    },
    handleSave(){
      this.orderProductApi.updateRemark(this.labelDetails).then(resp=>{
        this.$message.success("保存成功")
        this.$emit('close',false)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>