import api from '../api'
/**
 * 标签生产详情
 */
export class OrderProductInfoApi extends api {
  audit(data){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/orderAudit`,
      method: "post",
      data
    })
  }
  getTasks(data){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/workflow/getTasks`,
      method: "post",
      data
    })
  }
  getOrderInfoById (id) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/getOrderInfoById/${id}`,
      method: 'get'
    })
  }
  getLabelInfoByOrderId (orderId) {
    return this.request({
        url: `${this.servicePath}/${this.modulePath}/getLabelInfoByOrderId/${orderId}`,
        method: 'get'
    })
  }
  updateRemark(data){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/updateRemark`,
      method: "put",
      data
    })
  }
  exportInfoByOrderId(orderId){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/export/${orderId}`,
      responseType: 'blob',
      method: 'get'
    })
  }
  exportLabelInfo(orderId){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/exportLabelInfo/${orderId}`,
      responseType: 'blob',
      method: 'get'
    })
  }
  exportApplyLabelInfo(orderId){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/exportApplyLabelInfo/${orderId}`,
      responseType: 'blob',
      method: 'get'
    })
  }
  exportSecurityCodeInfo(orderId){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/exportSecurityCodeInfo/${orderId}`,
      method: 'get'
    })
  }
}

export default new OrderProductInfoApi('orderProductInfo')