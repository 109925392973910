import api from '../api'
/**
 * 标签生产列表
 */
export class OrderProductListApi extends api {
    audit(data){
        return this.request({
          url: `${this.servicePath}/${this.modulePath}/orderAudit`,
          method: "post",
          data
        })
    }
    getTasks(data){
        return this.request({
          url: `${this.servicePath}/${this.modulePath}/workflow/getTasks`,
          method: "post",
          data
        })
    }
    getOrderInfoById (id) {
      return this.request({
        url: `${this.servicePath}/${this.modulePath}/getOrderInfoById/${id}`,
        method: 'get'
      })
    }
    getLabelInfoByOrderId (orderId) {
      return this.request({
          url: `${this.servicePath}/${this.modulePath}/getLabelInfoByOrderId/${orderId}`,
          method: 'get'
      })
    }
    updateRemark(data){
      return this.request({
        url: `${this.servicePath}/${this.modulePath}/updateRemark`,
        method: "put",
        data
      })
  }
}

export default new OrderProductListApi('orderProduct')