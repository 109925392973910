<template>
    <list-condition-template ref="table" :rows="rows" :total="total" 
    @page-info-change="handlePageInfoChange" :defaultSort="{prop:'updateTime',order:'descending'}"
    v-model:listQuery="listQuery" :index-column="true">
      <template #condition>
        <el-form-item>
          <div class="demo-date-picker">
            <div class="block">
              <span class="demonstration">筛选日期：</span>
              <el-date-picker
                clearable
                value-format="YYYY-MM-DD"
                v-model="valueDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间">
              </el-date-picker>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-select @change="handleFilter" clearable style="margin-left: 10px; width: 150px" class="filter-item" placeholder="订单状态" v-model="listQuery.condition.orderStatus">
            <el-option v-for="item in statusOptions" :key="item.name" :label="item.type" :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input @keyup.enter="handleFilter" placeholder="物料代码/型号/制造商/订单批次  查询"  style="width: 250px;" v-model="listQuery.condition.keyword" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="filter-item" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleFilter">搜索</el-button>
        </el-form-item>
      </template>
    <template #columns>
        <el-table-column min-width="120px" label="订单状态" align="center">
           <template #default="scope">
            <el-tag :type="showFilter(scope.row)" style="width: 90px">{{scope.row.orderStatus?scope.row.orderStatus.type:''}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column min-width="100px" label="订单批次" property="orderBatch" align="center">
          <template #default="scope">
            <span>{{scope.row.orderBatch}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="100px" label="单据类型" align="center">标签采购单</el-table-column>
        <el-table-column min-width="150px" label="制单时间" align="center" sortable="order" property="orderTime">
          <template  #default="scope">
            <span>{{scope.row.orderTime}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="100px" label="制单人账号" property="createBy" align="center">
          <template #default="scope">
            <span>{{scope.row.createBy}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="80px" label="防伪码标签（套）" property="securityNumber" align="center">
          <template #default="scope">
            <span>{{scope.row.securityNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="150px" label="标签供应商" property="supplier.name" align="center">
          <template #default="scope">
            <span>{{scope.row.supplier.name}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200px" class-name="fixed-width">
          <template  #default="scope">
            <el-button type="primary" size="mini" @click="handleShow(scope.row)" v-waves>查看</el-button>
            <el-button style = "width:90px" v-if="scope.row.orderStatus.name==='PLACE_ORDER'" size="mini" type="success" @click="handleReceiptReceive(scope.row)" v-waves>开始生产</el-button>
            <el-button style = "width:90px" v-if="scope.row.orderStatus.name==='RECEIVE'" size="mini" type="success" @click="handleReceiptShipped(scope.row)" v-waves>完成并发货</el-button>
          </template>
        </el-table-column>
    </template>
    <el-dialog v-model="startupFormDialogShow" :append-to-body="true" width="80%">
        <component :is="startupForm" v-model:show="startupFormDialogShow" v-model:workflowNode="workflowNode" @close="startupFormDialogShow=false;getList()"></component>
    </el-dialog>
  </list-condition-template>
</template>

<script>
import waves from '@/directive/waves' // 水波纹指令
import listConditionTemplate from '@/components/templates/listConditionTemplate'
import orderProductListApi from '@/apis/order/orderProductList'
import ledgerForm from './ledgerForm'
export default {
  directives: {
    waves
  },
  components: {
    listConditionTemplate,
    ledgerForm
  },
  data () {
    return {
      statusOptions: [
        {
          name: 'PLACE_ORDER',
          type: '已下单'
        },
        {
          name: 'RECEIVE',
          type: '生产中'
        },
        {
          name: 'SHIPPED',
          type: '完成并发货'
        },
        {
          name: 'RECEIPT',
          type: '已收货'
        }
        // {
        //   name: 'CANCEL',
        //   type: '已取消'
        // }
      ],
      startupFormDialogShow: false,
      startupForm: null,
      valueDate: undefined,
      rows: null,
      total: 0,
      listQuery: {
        condition:{
          keyword: null,
          enabled: null,
          startTime: undefined,
          endTime: undefined,
          orderStatus: undefined
        },
        pageInfo:{
          pageIndex: 1,
          pageSize: 20,
          orders: []
        }
      },
      task: undefined,
      taskId: undefined,
      params:{
        businessKey: undefined,
        localVariables: {
            approved: undefined,
            note: undefined
        }
      },
      workflowNode: {
        businessObject: {
        }
      }
    }
  },
  filters: {
  },
  methods: {
    showFilter(row){
      if(row.orderStatus && row.orderStatus.name === 'RECEIPT'){
        return 'success'
      }else if(row.orderStatus && row.orderStatus.name === 'SHIPPED'){
        return 'warning'
      }else if(row.orderStatus && row.orderStatus.name === 'RECEIVE'){
        return 'info'
      }else if(row.orderStatus && row.orderStatus.name === 'CANCEL'){
        return 'danger'
      }else{
        return ' '
      }
    },
    handleFilter () {
      if(this.valueDate){
        this.listQuery.condition.startTime = this.valueDate[0] + " 00:00:00"
        this.listQuery.condition.endTime = this.valueDate[1] + " 23:59:59"
      }else{
        this.listQuery.condition.startTime = undefined
        this.listQuery.condition.endTime = undefined
      }
      this.getList()
    },
    handlePageInfoChange () {
      this.getList()
    },
    getList () {
      orderProductListApi
        .getPaged(this.listQuery)
        .then(response => {
          this.rows = response.data.content
          this.total = response.data.totalElements
        })
    },
    handleShow(row){
      //跳转到查看页面
      //this.$router.pushTo(this.$router.routesConfig.orderDetails, row.id)
      this.$router.push({ path: '/label/production/info/' + row.id })
    },
    handleReceiptReceive(row){
      //修改状态
      orderProductListApi.getTasks({
        assignee: this.$store.state.user.account,
        processDefinitionKeys: [row.processDefinitionKey],
        businessKey: row.id
      }).then(resp=>{
        var task = resp.data[0]
        if (task) {
          this.task = task
          this.taskId = task.id
          this.params.localVariables.approved = true
          this.params.localVariables.note = '通过'
          var orderStatus = {
            orderId: row.id,
            status: {
              type: '生产中',
              name: 'RECEIVE'
            }
          }
          orderProductListApi.audit({orderStatus: orderStatus,taskId:this.taskId,...this.params}).then(resp=>{
            if (resp.data) {
              this.$message.success("成功")
              this.getList()
            }
          })
        }else{
          this.$message.warning("无权操作")
          this.task = null
          this.taskId = null
        }
      })
    },
    handleReceiptShipped(row){
      this.workflowNode = {
        processInstanceId: row.processInstanceId,
        assignee: this.$store.state.user.account,
        processDefinitionKey: row.processDefinitionKey,
        businessKey: row.id,
        businessObject: row,
        isInfo: false
      }
      
      this.startupForm = 'ledgerForm'
      this.startupFormDialogShow = true
    }
  }
}
</script>
